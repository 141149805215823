import logo from './logo.svg';
import './App.css';
import { Flex, View } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { 
  ProfileCard 
} from './ui-components';


function App() {
  return <body  style={{
    backgroundColor: 'black',
  }}>
    <View>
      <Flex
      justifyContent="center">
        <ProfileCard />
        </Flex>
    </View>
</body>
}

export default App;
