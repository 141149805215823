/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
  useNavigateAction,
} from "@aws-amplify/ui-react/internal";
import {
  Button,
  Flex,
  Image,
  Text,
  useBreakpointValue,
} from "@aws-amplify/ui-react";
import MyIcon from "./MyIcon";
export default function ProfileCard(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      overrides: {
        image: {},
        Malekoo: {},
        "Frame 418": {},
        "Music, innovation, creativity, and spirituality": {},
        Name: {},
        MyIcon: {},
        "99 Followers": {},
        Followers: {},
        Button29766907: {},
        Button34602692: {},
        Button34782748: {},
        Button34602696: {},
        Button34782752: {},
        Button34782757: {},
        ProfileCard: {},
      },
      variantValues: { breakpoint: "base" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "base",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  const buttonTwoNineSevenSixSixNineZeroSevenOnClick = useNavigateAction({
    type: "url",
    url: "https://airtable.com/shr6XGqIJxiE1lDy0",
  });
  const buttonThreeFourSixZeroTwoSixNineTwoOnClick = useNavigateAction({
    type: "url",
    url: "https://www.tiktok.com/@malekoo.art",
  });
  const buttonThreeFourSevenEightTwoSevenFourEightOnClick = useNavigateAction({
    type: "url",
    url: "https://m.facebook.com/malekoo.art",
  });
  const buttonThreeFourSixZeroTwoSixNineSixOnClick = useNavigateAction({
    type: "url",
    url: "https://www.youtube.com/channel/UCRb2zs9pctvYhla1pQKEEQw",
  });
  const buttonThreeFourSevenEightTwoSevenFiveTwoOnClick = useNavigateAction({
    type: "url",
    url: "https://www.instagram.com/malekoo.art",
  });
  const buttonThreeFourSevenEightTwoSevenFiveSevenOnClick = useNavigateAction({
    type: "url",
    url: "https://twitter.com/malekoo_art",
  });
  return (
    <Flex
      gap="24px"
      direction="column"
      width="349px"
      height="750px"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="24px 24px 24px 24px"
      backgroundColor="rgba(0,0,0,1)"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "ProfileCard")}
    >
      <Image
        width="160px"
        height="160px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        borderRadius="160px"
        padding="0px 0px 0px 0px"
        objectFit="cover"
        src="https://s3.amazonaws.com/malekoo/assets/images/profile.jpg"
        {...getOverrideProps(overrides, "image")}
      ></Image>
      <Flex
        gap="8px"
        direction="column"
        width="unset"
        height="101px"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Name")}
      >
        <Flex
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="10px 10px 10px 10px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 418")}
        >
          <Text
            fontFamily="Inter"
            fontSize="20px"
            fontWeight="700"
            color="rgba(174,179,183,1)"
            lineHeight="25px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Malekoo"
            {...getOverrideProps(overrides, "Malekoo")}
          ></Text>
        </Flex>
        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="400"
          color="rgba(174,179,183,1)"
          lineHeight="24px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.01px"
          width="234px"
          height="50px"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Music, innovation, creativity, and spirituality"
          {...getOverrideProps(
            overrides,
            "Music, innovation, creativity, and spirituality"
          )}
        ></Text>
      </Flex>
      <Flex
        gap="16px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="none"
        {...getOverrideProps(overrides, "Followers")}
      >
        <MyIcon
          width="24px"
          height="24px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          type="group"
          {...getOverrideProps(overrides, "MyIcon")}
        ></MyIcon>
        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="400"
          color="rgba(48,64,80,1)"
          lineHeight="24px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.01px"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="99 Followers"
          {...getOverrideProps(overrides, "99 Followers")}
        ></Text>
      </Flex>
      <Button
        shrink="0"
        alignSelf="stretch"
        size="large"
        isDisabled={false}
        variation="primary"
        children="Contact Us"
        onClick={() => {
          buttonTwoNineSevenSixSixNineZeroSevenOnClick();
        }}
        {...getOverrideProps(overrides, "Button29766907")}
      ></Button>
      <Button
        shrink="0"
        alignSelf="stretch"
        size="large"
        isDisabled={false}
        variation="primary"
        children="TikTok"
        onClick={() => {
          buttonThreeFourSixZeroTwoSixNineTwoOnClick();
        }}
        {...getOverrideProps(overrides, "Button34602692")}
      ></Button>
      <Button
        shrink="0"
        alignSelf="stretch"
        size="large"
        isDisabled={false}
        variation="primary"
        children="Facebook"
        onClick={() => {
          buttonThreeFourSevenEightTwoSevenFourEightOnClick();
        }}
        {...getOverrideProps(overrides, "Button34782748")}
      ></Button>
      <Button
        shrink="0"
        alignSelf="stretch"
        size="large"
        isDisabled={false}
        variation="primary"
        children="Youtube"
        onClick={() => {
          buttonThreeFourSixZeroTwoSixNineSixOnClick();
        }}
        {...getOverrideProps(overrides, "Button34602696")}
      ></Button>
      <Button
        shrink="0"
        alignSelf="stretch"
        size="large"
        isDisabled={false}
        variation="primary"
        children="Instagram"
        onClick={() => {
          buttonThreeFourSevenEightTwoSevenFiveTwoOnClick();
        }}
        {...getOverrideProps(overrides, "Button34782752")}
      ></Button>
      <Button
        shrink="0"
        alignSelf="stretch"
        size="large"
        isDisabled={false}
        variation="primary"
        children="Twitter"
        onClick={() => {
          buttonThreeFourSevenEightTwoSevenFiveSevenOnClick();
        }}
        {...getOverrideProps(overrides, "Button34782757")}
      ></Button>
    </Flex>
  );
}
